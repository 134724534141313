import { withStyledSystem } from '@m/theme';
import { shade } from 'polished';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

function getActiveToggleTransform({ width, height }: StyledSystemProps) {
  const w = width || '45px';
  const h = height || '24px';
  return `translate3d(calc(${w} - ${h}), 0, 0)`;
}

export const Target = styled.label<{ thumbColor: string; inactiveColor: string }>`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 0;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    border: 0;
    transition:
      background-color 100ms linear 0ms,
      box-shadow 10ms linear 0ms;
    box-shadow: inset 0 0 0 1px ${({ inactiveColor }) => shade(0.2, inactiveColor)};
  }

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.225),
      0 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${({ thumbColor }) => thumbColor};
    transition: transform 150ms ease-out 50ms;
    transform: translate3d(-1px, 0, 0);
  }

  &:hover::after {
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  }
  &:active::after {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03));
  }
`;

type WrapProps = StyledSystemProps & { disabled?: boolean };
export const Wrap = styled.div<WrapProps>`
  position: relative;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 44px;
  height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  & ${Target}::after {
    width: ${({ height }) => height || '24px'};
    height: ${({ height }) => height || '24px'};
  }
  ${withStyledSystem}
`;

export const Checkbox = styled.input<{ activeColor: string; inactiveColor: string }>`
  position: absolute;
  visibility: hidden;

  & + ${Target}::before {
    background-color: ${({ inactiveColor }) => inactiveColor};
  }

  &:checked + ${Target}::before {
    background-color: ${({ activeColor }) => activeColor};
    box-shadow: inset 0 0 0 1px ${({ activeColor }) => shade(0.2, activeColor)};
    transition:
      background-color 200ms ease-in 0ms,
      box-shadow 200ms ease-in 0ms;
  }
  &:checked + ${Target}::after {
    transform: ${({ width, height }) => getActiveToggleTransform({ width, height })};
  }

  &:disabled + ${Target}::after {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    background-image: none;
  }

  &:disabled + ${Target} {
    cursor: not-allowed;
  }
`;
