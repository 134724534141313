import {
  forwardRef,
  useCallback,
  useMemo,
  HTMLAttributes,
  InputHTMLAttributes,
  ChangeEventHandler,
} from 'react';

import theme from '@m/theme';
import { nanoid } from 'nanoid';

import { Wrap, Checkbox, Target } from './Switch.styled';

import type { StyledSystemProps } from '@m/theme';

type ContainerProps = HTMLAttributes<HTMLDivElement> & StyledSystemProps;
type InputProps = InputHTMLAttributes<HTMLInputElement> & StyledSystemProps;

export interface SwitchProps extends InputProps {
  active?: boolean;
  disabled?: boolean;
  onToggle?: (active: boolean) => void;
  activeColor?: string;
  inactiveColor?: string;
  thumbColor?: string;
  containerProps?: ContainerProps;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      active,
      onToggle = () => {},
      activeColor = theme.colors.brand,
      inactiveColor = theme.colors.blue2,
      thumbColor = theme.colors.blue1,
      containerProps,
      ...passProps
    },
    ref,
  ) => {
    const switchId = useMemo(() => nanoid(), []);
    const handleToggle: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        const { checked, disabled } = e.target;
        !disabled && onToggle(checked);
      },
      [onToggle],
    );
    return (
      <Wrap {...containerProps}>
        <Checkbox
          {...passProps}
          type="checkbox"
          id={switchId}
          checked={active}
          disabled={passProps.disabled}
          activeColor={activeColor}
          inactiveColor={inactiveColor}
          onChange={handleToggle}
          data-testid="Switch_Checkbox"
          ref={ref}
        />
        <Target htmlFor={switchId} thumbColor={thumbColor} inactiveColor={inactiveColor} />
      </Wrap>
    );
  },
);

Switch.displayName = 'Switch';
