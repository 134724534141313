import { withStyledSystem } from '@m/theme';
import styled, { css } from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export const baseStyle = css`
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
  cursor: pointer;
  outline: 0;
  padding: 0;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  box-sizing: border-box;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  line-height: 1;
  letter-spacing: 0.03em;
  text-rendering: geometricPrecision;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.brand};

  &:disabled {
    filter: opacity(0.6) saturate(0.6);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    border: 1px solid currentColor;
  }
  &:active:not(:disabled) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    opacity: 0.95;
  }
`;

export const primaryStyle = css`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.brand};
  border: 1px solid rgba(0, 0, 0, 0.225);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

  &:hover:not(:disabled) {
    border: 1px solid rgba(0, 0, 0, 0.225);
    background-image: linear-gradient(rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
  }

  &:active:not(:disabled) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.225), rgba(0, 0, 0, 0.225));
    box-shadow: none;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const ButtonBase = styled.button<StyledSystemProps>`
  ${baseStyle}
  ${withStyledSystem}
`;

export const ButtonPrimary = styled.button<StyledSystemProps>`
  ${baseStyle}
  ${primaryStyle}
  ${withStyledSystem}
`;

export const ButtonSecondary = styled.button<StyledSystemProps>`
  ${baseStyle}
  color: ${(props) => props.theme.colors.brand};
  background-color: ${(props) => props.theme.colors.blue1};
  border: 1px solid ${(props) => props.theme.colors.blue3};

  &:hover:not(:disabled) {
    border: 1px solid ${(props) => props.theme.colors.blue5};
  }

  &:active:not(:disabled) {
    background-color: ${(props) => props.theme.colors.blue2};
    color: ${(props) => props.theme.colors.blue7};
  }
  ${withStyledSystem}
`;

export const ButtonDestructive = styled.button<StyledSystemProps>`
  ${baseStyle}
  ${primaryStyle}
  background-color: ${(props) => props.theme.colors.coral};
  ${withStyledSystem}
`;
