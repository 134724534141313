import { getConfig } from '@m/config';
import isLocalhost from '@m/utils/isLocalhost';

const { CONFIG_ENV } = getConfig();

export default function getEnvironment() {
  const { hostname } = window.location;
  if (
    isLocalhost() ||
    hostname.includes('localhost') ||
    hostname.includes('.local') ||
    hostname.includes('192.168')
  ) {
    return 'local';
  }
  if (CONFIG_ENV === 'staging' || hostname.includes('-staging.metropolis.io')) {
    return 'staging';
  }
  if (
    CONFIG_ENV === 'development' ||
    hostname.includes('-development.metropolis.io') ||
    hostname.includes('-dev.metropolis.io')
  ) {
    return 'development';
  }
  if (hostname.includes('-beta.metropolis.io')) {
    return 'production-beta';
  }
  return 'production';
}
