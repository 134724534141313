import type { HTMLAttributes } from 'react';

import { withStyledSystem } from '@m/theme';
import styled from 'styled-components';

import type { StyledSystemProps } from '@m/theme';

export type TextProps = HTMLAttributes<HTMLSpanElement> & StyledSystemProps;

export const Text = styled.span<StyledSystemProps>`
  font-family:
    'Neue Montreal',
    NeueMontreal-Medium,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;

export const TextBold = styled.span<StyledSystemProps>`
  font-family:
    'Neue Montreal',
    NeueMontreal-Bold,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;
