import React, { useCallback, useState } from 'react';
import type { ChangeEvent, ReactNode } from 'react';

import { ReactComponent as AlertCircle } from '@m/assets/svg/alert-circle.svg';
import { Box, Text, Button } from '@m/components/uikit';
import theme from '@m/theme';

import { Heading, Step } from 'apps/specialist/components/PageOverlay';
import Input from 'apps/specialist/components/TextInput';

type VerifyCodeProps = {
  name: string;
  active?: boolean;
  updateContentSize?: (size: { width: string | number; height: string | number }) => void;
  isLoading?: boolean;
  hasError?: boolean;
  errorMessage?: ReactNode;
  onSubmit: (code: string) => void;
};

export default function VerifyCode({
  name,
  active = false,
  updateContentSize = () => {},
  isLoading,
  hasError,
  errorMessage,
  onSubmit,
}: VerifyCodeProps) {
  const [code, setCode] = useState('');

  const handleChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCode(value);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(code);
  }, [code, onSubmit]);

  return (
    <Step
      active={active}
      width={280}
      height={300}
      name="login"
      updateContentSize={updateContentSize}
    >
      <Heading>Login Code</Heading>
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        paddingBottom="24px"
      >
        <Box
          flexDirection="row"
          justifyContent="center"
          width="100%"
          paddingTop="24px"
          paddingX="24px"
          flexGrow={0}
        >
          <Text fontSize="16px" lineHeight={1.35} textAlign="center">
            To complete login, please enter the 6-digit code we sent you.
          </Text>
        </Box>
        <Box
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          height="80px"
          flexShrink={0}
          flexGrow={1}
        >
          <Box width="196px" height="auto" flex={0} position="relative">
            <Input
              label="Verification Code"
              name="verificationCode"
              pattern="[0-9]*"
              inputMode="numeric"
              autoComplete="one-time-code"
              onChange={handleChange}
              value={code}
              css={`
                height: 64px !important;
                flex-shrink: 0;
              `}
            />
          </Box>
        </Box>
        <Button variant="primary" onClick={handleSubmit} width="200px">
          <span>Submit Code</span>
        </Button>
      </Box>
      {isLoading === false && hasError === true && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="36px"
        >
          <AlertCircle width={18} height={18} css="margin-right: 6px" fill={theme.colors.coral} />
          <Text fontSize="15px">{errorMessage}</Text>
        </Box>
      )}
    </Step>
  );
}
