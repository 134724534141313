import { getConfig } from '@m/config';
import loadjs from 'loadjs';
import Emitter from 'tiny-emitter';

/**
 * Helper class for handling Google Authentication
 */
class GoogleAuth {
  auth2: any;

  emitter: any;

  gapi: any;

  constructor() {
    // @ts-ignore ts-migrate(2351) FIXME: Type 'typeof import("/Users/jacob/Metropolis/web/n...
    this.emitter = new Emitter();
    this.loadGapi().then(() => this.loadAuth2());
  }

  /**
   * Loads `gapi` Google library
   *
   * @returns {Promise[gapi]}
   */
  async loadGapi() {
    if (this.gapi) {
      return this.gapi;
    }
    return new Promise((resolve, reject) => {
      loadjs('https://apis.google.com/js/client:platform.js', {
        success: () => {
          // @ts-ignore ts-migrate(2339) FIXME: Property 'gapi' does not exist on type 'Window & t
          const { gapi } = window;
          this.gapi = gapi;
          resolve(this.gapi);
        },
        error: (err) => {
          console.error(err); // eslint-disable-line
          reject(err);
        },
      });
    });
  }

  /**
   * Loads and initializes Google `auth2` module
   *
   * @returns {Promise[auth2]}
   */
  async loadAuth2() {
    if (this.auth2) {
      return this.auth2;
    }
    const gapi = await this.loadGapi();
    return new Promise((resolve) => {
      gapi.load('auth2', async () => {
        this.auth2 = await gapi.auth2.init({
          client_id: getConfig().GOOGLE_OAUTH_CLIENT_ID,
          fetch_basic_profile: true,
        });
        this.auth2.isSignedIn.listen(async (isSignedIn: any) => {
          if (isSignedIn) {
            const gUser = await this.getUser();
            this.emitter.emit('login', gUser);
            return;
          }
          this.emitter.emit('logout');
        });
        resolve(this.auth2);
        return this.auth2;
      });
    });
  }

  /**
   * Checks if a Google user is signed in
   *
   * @returns {Boolean}
   */
  isSignedIn() {
    return this.auth2 && this.auth2.isSignedIn.get();
  }

  /**
   * Retrieve basic profile info for signed in user
   *
   * @returns {Object} user  User profile data
   * @returns {String} user.firstName
   * @returns {String} user.lastName
   * @returns {String} user.email
   * @retunrs {String} user.avatar URL to profile avatar
   */
  async getUser() {
    const auth2 = await this.loadAuth2();
    if (this.isSignedIn()) {
      const profile = auth2.currentUser.get().getBasicProfile();
      return {
        firstName: profile.getGivenName(),
        lastName: profile.getFamilyName(),
        email: profile.getEmail(),
        avatar: profile.getImageUrl(),
      };
    }
    return {};
  }

  /**
   * Listens for `login` event and resolves logged in user profile
   *
   * @returns {Object} user  User profile data
   * @returns {String} user.firstName
   * @returns {String} user.lastName
   * @returns {String} user.email
   * @retunrs {String} user.avatar URL to profile avatar
   */
  async getUserOnLogin() {
    return new Promise((resolve) => {
      this.emitter.once('login', (user: any) => resolve(user));
    });
  }

  /**
   * Login using Google authentication
   *
   * @returns {Promise[{ code, email, avatar }]}
   */
  async login() {
    const auth2 = await this.loadAuth2();
    if (this.isSignedIn()) {
      await this.logout();
    }
    const response = await auth2.grantOfflineAccess({
      scope: 'profile email',
      prompt: 'select_account',
      fetch_basic_profile: true,
    });
    if (response.code) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'email' does not exist on type 'unknown'.
      const { email, avatar } = await this.getUserOnLogin();
      return {
        code: response.code,
        email,
        avatar,
        provider: 'google', // `provider` required by our api to validate cookie
      };
    }
    return response;
  }

  /**
   * Logout from Google profile
   */
  async logout() {
    const auth2 = await this.loadAuth2();
    auth2.signOut();
  }
}

export default GoogleAuth;
