import isPropValid from '@emotion/is-prop-valid';
import theme, { WebFonts } from '@m/theme';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
}

export default function Providers({ children }: { children?: React.ReactNode }) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ThemeProvider theme={theme}>
        <WebFonts />
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
    </StyleSheetManager>
  );
}
